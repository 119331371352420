<script setup>
import { Link } from '@inertiajs/vue3';
import { computed, inject } from 'vue';

import Icon from '@/Components/Icon.vue';

// Icons
import { capitalFirstLetter } from '@/utilities';
import GreenCheckmark from '@i/green-checkmark.svg';
import OrangeCheckmark from '@i/orange-checkmark.svg';

const dayjs = inject('dayjs');
const route = inject('route');

const props = defineProps({
    shift: Object,
    showDate: Boolean,
});

const start = computed(() => dayjs(props.shift.start));
const end = computed(() => dayjs(props.shift.end));
const in12Hours = dayjs(props.shift.start).diff(dayjs(), 'hour') < 12 && dayjs().isBefore(props.shift.start);
const relevantApplications=[];
// const relevantApplications = computed(() =>
//     props.shift.applications.filter(
//         (app) =>
//             app.status !== 'retracted' &&
//             app.status !== 'lapsed' &&
//             app.status !== 'acceptance_lapsed' &&
//             app.status !== 'rejected' &&
//             app.status !== 'filled_by_others' &&
//             app.status !== 'absent' &&
//             app.status !== 'illness' &&
//             app.status !== 'no_show'
//     )
// );
</script>
<template>
    <div :class="{ 'bg-orange/30 mb-2 px-2 rounded-lg': in12Hours }">
        <div
            class="relative flex justify-between w-full py-3 pl-8 overflow-hidden border-blue/30"
            :class="{ 'border-t': !in12Hours }"
        >
            <div
                v-if="shift.status === 'open' && relevantApplications.length === 0"
                class="absolute left-0 w-1 rounded top-3 bottom-3 bg-red"
            ></div>
            <div v-else-if="shift.status === 'open'" class="absolute left-0 w-1 rounded top-3 bottom-3 bg-orange"></div>
            <div
                v-else-if="shift.status === 'retracted'"
                class="absolute left-0 w-1 rounded top-3 bottom-3 bg-red"
            ></div>
            <div v-else class="absolute left-0 w-1 rounded top-3 bottom-3 bg-green"></div>

            <Link
                class="flex flex-col flex-1 px-4 py-1 duration-150 rounded cursor-pointer hover:bg-blue/10"
                :href="route('location.shifts.show', { id: shift.id })"
            >
                <div class="flex items-center gap-1 text-base font-bold text-blue">
                    {{ shift.position.public_name }}

                    <span class="text-sm font-bold text-blue" v-if="shift.number_of_shifts > 1">
                        ({{ shift.filled_shift_count ?? 0 }}/{{ shift.number_of_shifts }})
                    </span>
                </div>
                <span class="text-base text-blue">
                    <span v-if="showDate">
                        {{ start.format('D') }}
                        {{ capitalFirstLetter(dayjs().localeData().months()[start.format('M') - 1]) }}
                    </span>

                    {{ start.format('HH:mm') }} -
                    {{ end.format('HH:mm') }}
                </span>
            </Link>

            <div class="flex items-center justify-between">
                <div class="flex items-center gap-6">
                    <div class="flex items-center gap-1" v-if="shift.status == 'open'">
                        <template v-if="relevantApplications.length > 0">
                            <Icon :icon="OrangeCheckmark" />

                            <div class="text-sm font-bold text-blue">
                                {{ relevantApplications.length }}
                            </div>
                        </template>

                        <div class="flex items-center gap-1" v-if="shift.filled_shift_count">
                            <Icon :icon="GreenCheckmark" />

                            <div class="text-sm font-bold text-blue" v-if="shift.number_of_shifts > 1">
                                {{ shift.filled_shift_count }}
                            </div>
                        </div>
                    </div>
                    <slot></slot>
                </div>
            </div>
        </div>
    </div>
</template>
